<template>
  <div class="t-flex t-items-center t-justify-center t-flex-col t-h-screen">
    <lottie-animation
      v-if="!loading"
      :animationData="animationData"
      class="t-w-[56px]"
    />
    <lottie-animation
      :loop="true"
      :autoPlay="false"
      ref="loading"
      :style="displayLoading"
      :animationData="animationData"
      class="t-w-[56px]"
    />
    <div
      class="t-p-5 t-sm:p-0 md:t-w-[370px] t-mx-auto t-text-center t-space-y-[40px] t-box-content t-mt-5 md:t-mt-8"
    >
      <div>
        <h1 class="t-text-[27px] md:t-text-[40px] t-font-bold t-text-gray-900">
          One more click ...
        </h1>
        <p class="t-mt-1 t-text-center t-text-gray-500 t-max-w">
          To prevent robots from consuming your magic link, a manual action is required
        </p>
      </div>

      <form v-on:submit.prevent="handleCallback">
        <button
          class="t-w-full"
          @submit.prevent="handleCallback"
        >
          Authenticate me
        </button>
      </form>

      <div class="t-flex t-justify-between">
        <span>No account?</span>
        <router-link to="/register">Sign up</router-link>
      </div>
    </div>
    <footer style="visibility: hidden">
      <a href="https://www.reversecontact.com/">Return to homepage</a>
    </footer>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
import Swal from "sweetalert2";
import animationData from "../assets/lotties/ReverseContactAnimation.json";

export default {
  components: { LottieAnimation },
  data() {
    return {
      hcaptcha_sitekey: process.env.VUE_APP_HCAPTCHA_PUBLIC_KEY,
      loading: false,
      animationData,
      email: "",
    };
  },
  mounted() {
    if (localStorage.getItem("user")) {
      this.$router.push({ name: "UniqueSearch" });
    }
  },
  methods:{
    async handleCallback() {
      try {
        // Récupérer les fragments de l'URL
        const hash = window.location.hash.substring(1);
        const params = new URLSearchParams(hash);
        const access_token = params.get("access_token");
        const error = params.get("error_code");

        if (error || !access_token) {
          Swal.fire({
            icon: "error",
            title: "Authentication Error",
            text: "Email link is invalid or has expired",
          });
        }
        this.loading = true;
        this.$refs.loading.play();

        this.$store.dispatch("auth", access_token);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Authentication Error",
          text: "Please contact support",
        });
      }
    },
  },
  computed:{
    displayLoading: function () {
      if (this.loading) return "display: block !important;";
      else return "display: none !important;";
    },
  }
};
</script>
