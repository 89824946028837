<template>
  <div v-if="user == null"></div>
  <div v-else class="py-5 px-4 ma-0">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="headline ">
          🔑 My API Key
        </v-card-title>

        <v-card-text class="text-center">
          <v-col cols="12">
            <span class="text-subtitle-1 error--text"><span class="font-weight-black"> Warning: </span> An account can only have one API key, revoke active key will generate new one. Only share this key with trusted services.</span>
          </v-col>
          <v-text-field
            solo
            disabled
            :value="secretKey.value"
          ></v-text-field>
         </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="$theme.secondary"
            class="white--text"
            text
            @click="dialogRevokeAPIKey"
          >
            Revoke this API Key
          </v-btn>
          <v-btn
            :color="$theme.primary"
            class="white--text"
            text
            @click="dialog = false"
          >
            Back
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <p>To integrate Reversecontact in your favorite tools</p>
        <v-card class="search-card text-center">
          <v-row>
            <v-col cols="12">
              <v-card-title>Your API Key 🔑</v-card-title>
              <p>Your API key allows you to connect all your external services.</p>
            </v-col>
            <v-col cols="6" offset="3" class="px-12">
              <v-btn
              @click="generateAPIKey()"
            block
            large
            class="white--text mb-3"
            depressed
            :color="$theme.secondary"
          >
            <span>Get my API key </span>
          </v-btn>
            </v-col>
          </v-row>
          <v-row align="center" justify="space-around">
            <v-btn href="https://docs.reversecontact.com/" target="_blank" text>
              View API documentation
              <v-icon
                right
                dark
              >
                mdi-open-in-new
              </v-icon>
            </v-btn>
          </v-row>
        </v-card>
      <v-card class="search-card text-center">
        <v-row>
          <v-col cols="12">
            <v-card-title>Integration 🔄</v-card-title>
          </v-col>
            <v-col cols="4" v-for="integration in integrations" :key="integration.id">
              <v-card class="mx-auto" max-width="300" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="overline mb-4">{{ integration.category }}</div>
                    <v-list-item-title class="headline mb-1">{{ integration.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ integration.description }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar tile size="80">
                    <img
                      :src="'https://logos-comptes.s3.eu-west-3.amazonaws.com/'+ integration.name.toLowerCase() + '.png'"
                      alt="Logo"
                    />
                  </v-list-item-avatar>
                </v-list-item>

                <v-card-actions>
                  <div class="text-center">
                    <v-btn
                      @click="integrate(integration)"
                      class="white--text"
                      rounded
                      :color="$theme.secondary"
                      target="_blank"
                    >Connect {{ integration.name }}</v-btn>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
        </v-row>
      </v-card>
  </div>
</template>

<script>
import AuthServices from "@/services/AuthServices";
import Swal from "sweetalert2";
import { mapState } from "vuex";
export default {
  data() {
    return {
      dialog:false,
      secretKey: {
        newOne: false,
        value: ""
      },
      integrations: [
        {
          id: 1,
          category: "CRM",
          name: "Hubspot",
          description: "Sync your Hubspot CRM to Reverse Contact data",
          link: "https://help.reversecontact.com/en/article/how-to-connect-reverse-contact-to-hubspot-with-our-native-integration-170ev3f/",
          premium: true
        },
        {
          id: 2,
          category: "CRM",
          name: "Salesforce",
          description: "Sync your Salesforce CRM to Reverse Contact data",
          link: "https://help.reversecontact.com/en/article/how-to-connect-reverse-contact-to-salesforce-with-our-native-integration-tvvqt5/",
          premium: true
        },
        {
          id: 3,
          category: "CRM",
          name: "Pipedrive",
          description: "Sync your Pipedrive CRM to Reverse Contact data",
          link: "https://help.reversecontact.com/en/article/how-to-connect-reverse-contact-to-pipedrive-with-our-native-integration-gwy51d/?bust=1720601933614",
          premium: true
        },
        {
          id: 4,
          category: "Automation",
          name: "Zapier",
          description: "Use your Reversecontact result with Zapier",
          link: "https://zapier.com/apps/reverse-contact/integrations",
          premium: false
        },
        {
          id: 5,
          category: "Automation",
          name: "Make",
          description: "Use your Reversecontact result with Make",
          link: "https://www.make.com/en/integrations/reversecontact",
          premium: false
        },
        {
          id: 6,
          category: "Automation",
          name: "Pipedream",
          description: "Use your Reversecontact result with Pipedream",
          link: "https://pipedream.com/apps/reversecontact",
          premium: false
        }
      ]
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    async integrate(integration) {
      if (integration.premium) {
        if (this.user.stripe.subscriptionId != "" && this.user.stripe.customerId != "") {
          window.open(integration.link, '_blank');
        } else {
          Swal.fire({
            icon: "warning",
            title: "Upgrade your plan",
            text: "You need to get a paid plan to use the " + integration.name + " integration.",
          })
          this.$router.push({ name: "Pricing" });
        }
      } else {
        window.open(integration.link, '_blank');
      }
    },
    async generateAPIKey() {
      this.dialog = true
      var response = await AuthServices.getApiKey();
      if (response.data.success) {
        this.secretKey.value = response.data.secretKey;
        this.secretKey.newOne = true;
      }
    },
    dialogRevokeAPIKey() {
      Swal.fire({
        title: 'Are you sure?',
        text: "After generating a new key, services using your current key will no longer be able to connect!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, revoke my key!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.revokeAPIKey()
        }
      })
    },
    async revokeAPIKey() {
      var response = await AuthServices.revokeApiKey();
      if (response.data.success) {
        this.secretKey.value = response.data.secretKey;
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: `New API key generated`,
          showConfirmButton: false,
          timer: 3000
        });
      }
    },
  }
};
</script>

<style>
.search-card {
  padding: 14px 24px;
  margin: 0 0 20px 0 !important;
  width: 100%;
  border-radius: 8px !important;
}
</style>
