var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user == null)?_c('div',[_vm._v("Loading...")]):_c('nav',[_c('v-app-bar',{attrs:{"app":"","flat":"","clipped-left":"","outlined":"","hide-overlay":"","color":"white"}},[_c('v-menu',{attrs:{"rounded":"lg","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('div',_vm._g(_vm._b({staticClass:"workspace-select"},'div',attrs,false),on),[_c('img',{staticClass:"workspace-logo",attrs:{"src":require("@/assets/reversecontact/ReverseContact_256.png"),"alt":"workspace-reversecontact"}}),_c('div',{staticStyle:{"display":"inline-block"}},[_c('div',{staticClass:"workspace-label"},[_vm._v("WORKSPACE")]),_c('div',{staticClass:"workspace-name"},[_vm._v("Reverse Contact")])])])]}}])}),_c('v-app-bar-nav-icon',{staticClass:"grey--text",attrs:{"hidden":_vm.drawer},on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-spacer'),(_vm.showDrawer)?[_c('v-btn',{staticClass:"white--text btn-upgrade",style:('background-image: linear-gradient(to right, ' +
          _vm.$theme.secondary +
          ', ' +
          _vm.$theme.secondary2 +
          ');'),attrs:{"large":"","router":"","to":"/pricing"}},[_c('span',[_vm._v("Upgrade")])]),(_vm.user.quota.total < 99999999)?_c('div',{staticClass:"px-5 credits-container"},[_c('v-icon',{attrs:{"left":"","color":_vm.$theme.secondary}},[_vm._v("mdi-lightning-bolt-outline")]),_c('span',{staticClass:"credits-label"},[_vm._v("Credits")]),_c('v-progress-linear',{staticStyle:{"margin":"0px 16px","width":"100px","height":"6px"},attrs:{"value":(_vm.user.quota.credits / _vm.user.quota.total) * 100,"color":_vm.$theme.secondary,"rounded":""}}),_c('small',{staticClass:"font-weight-bold",style:('color: ' + _vm.$theme.secondary)},[_vm._v(" "+_vm._s(_vm.user.quota.credits)+" contact"+_vm._s(_vm.user.quota.credits > 1 ? "s" : "")+" ")])],1):_c('div',{staticClass:"px-5 credits-container"},[_c('v-icon',{attrs:{"left":"","color":_vm.$theme.secondary}},[_vm._v("mdi-lightning-bolt-outline")]),_c('small',{staticClass:"font-weight-bold",style:('color: ' + _vm.$theme.secondary)},[_vm._v("Unlimited plan")])],1),(
          _vm.user.quota.credits - 100 >
          _vm.user.quota.total_rate_limit - _vm.user.quota.rate_limit
        )?_c('div',{staticClass:"px-5 credits-container"},[_c('v-icon',{attrs:{"left":"","color":_vm.$theme.secondary}},[_vm._v("mdi-speedometer-medium")]),_c('span',{staticClass:"credits-label"},[_vm._v("Daily limit")]),_c('small',{staticClass:"px-5 font-weight-bold",style:('color: ' + _vm.$theme.secondary)},[_vm._v(" "+_vm._s(_vm.user.quota.rate_limit)+" / "+_vm._s(_vm.user.quota.total_rate_limit)+" ")])],1):_vm._e()]:[_c('v-btn',{attrs:{"to":"/","depressed":"","color":_vm.$theme.secondary,"outlined":""}},[_vm._v("Back to dashboard")])],_c('div',{staticClass:"user-infos"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.user.firstName))]),_c('img',{staticClass:"avatar",attrs:{"src":require("@/assets/visum/visum-avatar.png")},on:{"click":_vm.redirectToProfile}})])],2),(_vm.showDrawer)?_c('v-navigation-drawer',{staticClass:"px-4 side-menu d-flex",attrs:{"floating":"","clipped":"","app":"","color":"fbfdfe","width":"300"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"mt-5",staticStyle:{"background-color":"white"}},[_c('v-list',{staticClass:"py-0",attrs:{"nav":"","rounded":""}},_vm._l((_vm.sections),function(item){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(!item.bottom),expression:"!item.bottom"}],key:item.title,attrs:{"color":_vm.$theme.secondary,"disabled":item.disabled},on:{"click":item.action}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":_vm.currentRouteName == item.route ? _vm.$theme.secondary : 'black'},domProps:{"textContent":_vm._s(item.icon)}})],1),(item.label !== false)?_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"padding-bottom":"8px"}},[_c('v-badge',{attrs:{"color":_vm.currentRouteName == item.route ? _vm.$theme.secondary : 'black',"content":item.label}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1):_c('v-list-item-content',[_c('v-list-item-title',{style:('color:' + _vm.currentRouteName == item.route
                  ? '#F76B1C !important;'
                  : 'black')},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)}),1)],1),_c('div',{staticClass:"mb-5 mt-auto",staticStyle:{"background-color":"white"}},[_c('v-list',{staticClass:"py-0",attrs:{"nav":"","rounded":""}},_vm._l((_vm.sections),function(item){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(item.bottom),expression:"item.bottom"}],key:item.title,attrs:{"color":_vm.$theme.secondary,"disabled":item.disabled},on:{"click":item.action}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":_vm.currentRouteName == item.route ? _vm.$theme.secondary : 'black'},domProps:{"textContent":_vm._s(item.icon)}})],1),(item.label !== false)?_c('v-list-item-content',[_c('v-list-item-title',{class:_vm.currentRouteName == item.route
                  ? _vm.$theme.secondary
                  : 'black-secondary'},[_c('v-badge',{attrs:{"color":_vm.currentRouteName == item.route ? _vm.$theme.secondary : 'black',"content":item.label}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1):_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title)+" ")])],1)],1)}),1)],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }