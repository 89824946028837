<template>
  <div class="t-flex t-items-center t-justify-center t-flex-col t-h-screen">
    <lottie-animation
      v-if="!loading"
      :animationData="animationData"
      class="t-w-[56px]"
    />
    <lottie-animation
      :loop="true"
      :autoPlay="false"
      ref="loading"
      :style="displayLoading"
      :animationData="animationData"
      class="t-w-[56px]"
    />
    <div
      class="t-p-5 t-sm:p-0 md:t-w-[370px] t-mx-auto t-text-center t-space-y-[40px] t-box-content t-mt-5 md:t-mt-8"
    >
      <div>
        <h1 class="t-text-[27px] md:t-text-[40px] t-font-bold t-text-gray-900">
          Welcome back
        </h1>
        <p class="t-mt-1 t-text-center t-text-gray-500 t-max-w">
          Let's turn any email into business opportunity
        </p>
      </div>

      <form v-on:submit.prevent="onSubmit">
        <input
          type="email"
          v-model="email"
          placeholder="email@domain.com"
          class="t-mb-6"
          autofocus
        />
        <vue-hcaptcha
          size="invisible"
          ref="hcaptcha"
          :sitekey="hcaptcha_sitekey"
          @verify="onVerify"
          @expired="onExpire"
          @challengeExpired="onChallengeExpire"
          @error="onError"
        ></vue-hcaptcha>
        <button
          class="t-w-full"
          :disabled="isDisabled"
          @submit.prevent="onSubmit"
        >
          Log in
        </button>
      </form>

      <div class="t-flex t-justify-between">
        <span>No account?</span>
        <router-link to="/register">Sign up</router-link>
      </div>
    </div>
    <footer style="visibility: hidden">
      <a href="https://www.reversecontact.com/">Return to homepage</a>
    </footer>
  </div>
</template>

<script>
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import LottieAnimation from "lottie-web-vue";
import Swal from "sweetalert2";
import animationData from "../assets/lotties/ReverseContactAnimation.json";

export default {
  components: { LottieAnimation, VueHcaptcha },
  data() {
    return {
      hcaptcha_sitekey: process.env.VUE_APP_HCAPTCHA_PUBLIC_KEY,
      loading: false,
      animationData,
      email: "",
      verified: false,
      expired: false,
      token: null,
      error: null,
    };
  },
  mounted() {
    if (localStorage.getItem("user")) {
      this.$router.push({ name: "UniqueSearch" });
    }
  },
  methods: {
    onExpire() {
      this.verified = false;
      this.token = null;
      this.expired = true;
      console.log("Challenge expired.");
    },
    onChallengeExpire() {
      this.verified = false;
      this.token = null;
      this.expired = true;
      console.log("Challenge expired.");
    },
    onError(err) {
      this.token = null;
      this.error = err;
      Swal.fire({
        icon: "error",
        title: "An error occurred",
        text: "Please try again later.",
      });
    },
    onSubmit() {
      this.$refs.hcaptcha.execute();
    },
    onVerify(token) {
      this.verified = true;
      this.login(token);
    },
    async login(token) {
      this.loading = true;
      this.$refs.loading.play();

      this.$store
        .dispatch("getOTP", { email: this.email, captcha: token })
        .then(() => {
          this.loading = false;
          this.$refs.loading.stop();
          Swal.fire({
            icon: "success",
            title: "An email has been sent to you",
            text: "Check your inbox to log in!",
            timer: 4000,
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$refs.loading.stop();
          Swal.fire({
            icon: "error",
            title: "An error occurred",
            text: "Please try again later.",
          });
        });
    },
  },
  computed: {
    displayLoading: function () {
      if (this.loading) return "display: block !important;";
      else return "display: none !important;";
    },
    isDisabled: function () {
      return (
        !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.email
        ) || this.loading
      );
    },
  },
};
</script>
